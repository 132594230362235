<template>
    <div id="waiting-pump" class="fuel-body">
        <div class="waiting-header">
            <h3>{{$t("fillUp")}}</h3>
        </div>
        <div class="waiting-body">
            <div class="waiting-text">
                <h4>{{$t("waitingTextHeader", {fuelType : fuelType.label || ""})}}.</h4>
                <span>{{$t("waitingTextPhoneWarn")}}</span><br>
                <small>{{$t("waitingTextMaxAmount", {maxAmount : 120})}}</small>
            </div>
            <lottie class="lottie-anim" :options="defaultOptions" v-on:animCreated="handleAnimation" />
        </div>
    </div>
</template>

<script>
    var MQTT = require("@/assets/js/Fuel/MqttService")
    import Lottie from 'vue-lottie';
    var animationData = require('@/assets/img/waves.json');

    export default {
        name: 'Waiting',
        components: {
            Lottie
        },
        data() {
            return {
                defaultOptions: {
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMinYMin slice',
                        clearCanvas: false
                    }
                },
                animationSpeed: 1,
                fuelType: "",
                anim:""
            }
        },
        methods: {
            handleAnimation: function (anim) {
                this.anim = anim;
            }
        },
        created() {
            var selectedFuel = JSON.parse(sessionStorage.getItem("DKC_selectedFuel"));
            if (selectedFuel == null) {
                this.$router.push(this.$parent.base + "/selection")
            } else {
                this.fuelType = selectedFuel;
                let mqttMessage = {
                    Name : localStorage.getItem("DKC_firstname") + " " + localStorage.getItem("DKC_lastname"), 
                    FuelType : this.fuelType.value
                };
                MQTT.publishMessage('START_FUEL', JSON.stringify(mqttMessage));
                MQTT.subscribe('END_FUEL', (message) => {
                    let total = JSON.parse(message);
                    localStorage.setItem('DKC_fuel_amount', total.Amount || 0);
                    localStorage.setItem('DKC_fuel_qty', total.Quantity || 0);
                    this.$parent.loader.showLoader = true;
                    this.$parent.loader.content = this.$t('pumpLoadingText');
                    setTimeout(()=>{
                        this.$router.push(this.$parent.base + '/ticket')
                    }, 2000)
                })
            }
        }
    }
</script>